import { makeStyles, Theme } from '@material-ui/core'
export const useSanctionStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: '100vh'
  },
  researchBlockCard: {
    border: '1px solid blue'
  },
  comment: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column'
  },
  cropHeight: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.text.secondary
  },
  headerBackground: {
    width: '100%',
    background: theme.palette.grey[50],
    padding: 30,
    marginBottom: 5
  },
  select: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 10,
    height: 50,
    padding: 5,
    '& > .MuiInput-underline:before': {
      color: 'red',
      display: 'none!important',
      borderBottom: 'none!important'
    }
  },
  labelTitle: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    lineHeight: '16px',
    margin: '0px!important'
  },
  gridLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  menu: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  tab: {
    display: 'flex',
    alignItems: 'start',
    '& span': {
      [theme.breakpoints.down(690)]: {
        fontSize: 10
      },
      [theme.breakpoints.down(500)]: {
        fontSize: 12
      }
    }
  },
  gridSelect: {
    padding: 8,
    '& .MuiInputBase-root': {
      '& .MuiInput-underline:before': {
        display: 'unset',
        borderBottom: 'unset!important'
      }
    }
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'fit-content'
  },
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    maxWidth: 'fit-content'
  },
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    lineHeight: '16px'
  },
  value: {
    fontSize: 14,
    // color: theme.palette.secondary.main,
    lineHeight: '16px'
  },
  tabs: {
    width: '100%',
    background: theme.palette.grey[50],
    padding: 30,

    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-root': {
      backgroundColor: 'transparent', // Default tab background
      color: theme.palette.text.primary, // Default tab text color
      transition: 'background-color 0.3s'
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.main, // Background for active tab
      color: theme.palette.common.white // Text color for active tab
    }
  },
  flagIcon: {
    width: 30,
    height: 30,
    marginRight: 5,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  },
  contentContainer: {
    margin: 0,
    width: '100%'
  },
  iconTextdiv: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main
    },
    '& p': {
      margin: '5px'
    }
  },
  title: {
    fontSize: 24,
    lineHeight: '33px',
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.text.primary
  },
  tagBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  icon: {
    marginRight: 10,
    color: theme.palette.secondary.main
  },
  dateBox: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main
  },
  linkButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    borderRadius: 5,
    textTransform: 'unset',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white
    }
  },
  name: {
    color: theme.palette.secondary.main,
    minWidth: 'fit-content',
    marginInlineEnd: 5,
    [theme.breakpoints.down(690)]: {
      fontSize: 14
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 12
    }
  },
  countryName: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center'
  },
  gridBox: {
    display: 'flex',
    alignItems: 'center',
    marginInlineEnd: '2%'
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },

  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: '19px',
    textAlign: 'justify',
    display: 'flex',
    alignItems: 'start',
    marginBlockStart: '0px!important'
  },
  textMargin: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 30px'
  },
  img: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 5,
    marginTop: 30,
    width: 350,
    height: 250
  }
}))
