import { FC, useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import { observer } from 'mobx-react-lite'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import SectionTitle from 'components/common/SectionTitle'
import { Login } from 'services/login/auth.service'
import { useLoginStyles } from './Login.styles'
import { useHistory } from 'react-router-dom'

const ERROR_MESSAGES = {
  wrongCredentials: 'Wrong credentials, please try again.',
  noPermissions: "You don't have permissions to view sanctions.",
  serverError: 'Something went wrong. Please try again later.'
}

const LoginForm: FC = () => {
  const classes = useLoginStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const initialValues = {
    username: '',
    password: ''
  }

  const validationSchema = Yup.object({
    username: Yup.string().required('User Name is required!'),
    password: Yup.string().required('Password is required!')
  })

  const handleLogin = async (formValues: {
    username: string
    password: string
  }) => {
    setLoading(true)
    try {
      const response = await Login(formValues.username, formValues.password)
      console.log({ response })

      if (!response || (!response.data && response.data != '')) {
        throw new Error(ERROR_MESSAGES.serverError)
      }

      const { user, expire, token } = response.data
      const { status } = response

      localStorage.setItem('authorizeStatus', String(status))
      localStorage.setItem('expired', expire || '')
      localStorage.setItem('user_name', user?.name || '')

      if (status === 3 || status === 4) {
        toast.error(ERROR_MESSAGES.wrongCredentials)
        return
      }

      if (!user?.permissions.includes('view sanction entity')) {
        toast.error(ERROR_MESSAGES.noPermissions)
        localStorage.setItem('have_permission', 'false')
        history.push('/home')
        return
      }

      localStorage.setItem('have_permission', 'true')
      localStorage.setItem('token', token || '')
      history.push('/sanctions')
    } catch (error: any) {
      toast.error(error?.message || ERROR_MESSAGES.serverError)
      setMessage(error?.message || ERROR_MESSAGES.serverError)
    } finally {
      setLoading(false)
    }
  }

  const navigateTo = (path: string) => history.push(path)

  const FormField = ({
    name,
    type,
    label
  }: {
    name: string
    type: string
    label: string
  }) => (
    <Grid item xs={12} className={classes.field}>
      <label className={classes.label} htmlFor={name}>
        {label}
      </label>
      <Field name={name} type={type} className="form-control" />
      <ErrorMessage name={name} component="Box" className={classes.alert} />
    </Grid>
  )

  return (
    <Box>
      <SectionTitle background="sanction" isShown={false} />
      <Box className={classes.card}>
        <Box className={classes.form}>
          <Box className={classes.sectionHead}>
            <Typography className={classes.titleHead}>
              SanctionsRuBase+
            </Typography>
          </Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form className={classes.formik}>
              <Grid container className={classes.container}>
                <FormField
                  name="username"
                  type="text"
                  label="Имя пользователя"
                />
                <FormField name="password" type="password" label="Пароль" />
              </Grid>

              {message && (
                <Box role="alert" className={classes.alert}>
                  {message}
                </Box>
              )}

              <Grid item xs={12} className={classes.forgotPass}>
                <Typography
                  className={classes.forgotText}
                  onClick={() => navigateTo('/register')}
                >
                  Зарегистрировать новый аккаунт
                </Typography>
                <Typography
                  className={classes.forgotText}
                  onClick={() => navigateTo('/reset-password')}
                >
                  Забыли пароль?
                </Typography>
              </Grid>

              <Box className={classes.buttonBox}>
                <Button
                  type="submit"
                  className={classes.button}
                  disabled={loading}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                  Войти
                </Button>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(LoginForm)
