// command image
import { FilterOptions } from 'models/filters'

export const defaultPaging = {
  page: 0,
  psize: 8,
  count: 0
}

export const defaultFilterOptions: FilterOptions = {
  block: [],
  type: [],
  dtype: [],
  author: '',
  title: '',
  text: '',
  date: '',
  country: [],
  documentType: [],
  period: {
    start: '',
    end: ''
  },
  authors: [],
  domain: [],
  is_economic: '',
  messageCountry: '',
  messageType: '',
  body: '',
  value: ''
}

export const defaultArrayItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export enum EndElement {
  END = 6,
  NON_DISPLAY = 5
}
