import { makeAutoObservable, runInAction } from 'mobx'
import axios from 'axios'
import {
  internalSanctionsItem,
  internalSanctionsList,
  PartnerItem,
  SanctionImplementationItem,
  SanctionMessageItem
} from 'models'
import { defaultPaging } from 'enums'
import { FilterOptions } from 'models/filters'
import { format } from 'date-fns'
import {
  defaultHomeSanctions,
  defaultSanctionImplementation,
  defaultSanctionMessage,
  defaultSanctionsDetail
} from './sanctions.data'
import { returnIds } from 'utils'
const urlSanctions = '/api/sanctions'
const urlSanctionMessages = '/api/sanction_message'
const urlSanctionMessageDetail = '/api/sanction_message'
const urlSanctionImplementation = '/api/sanction_implementaion'
const urlDetail = '/api/sanctions'
const homeSanctionUrl = '/api/block/front'

class SanctionsService {
  public sanctions: internalSanctionsList[] = []
  public sanctionMessages: internalSanctionsList[] = []
  public sanctionImplementations: internalSanctionsList[] = []
  public homeSanctions = defaultHomeSanctions

  public detail: internalSanctionsItem = defaultSanctionsDetail
  public sanctionMessageDetail: SanctionMessageItem = defaultSanctionMessage
  public sanctionImplementationDetail: SanctionImplementationItem =
    defaultSanctionImplementation
  public partners: PartnerItem[] = []
  public paging = defaultPaging
  private filter = {}
  public lang = ''
  public loading = false

  constructor() {
    makeAutoObservable(this)
  }

  async fetchHomeSanctions(language: string) {
    try {
      runInAction(() => (this.loading = true))

      const result = await axios.get(homeSanctionUrl)

      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data
        console.log(data, 'homeSanctionDataaaaa')

        this.homeSanctions = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchSanctions(language: string) {
    const token = localStorage.getItem('token')?.toString()
    console.log('11111')

    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...defaultPaging
      }
      const headers = {
        JWT: `${token}`
      }

      const result = await axios.get(urlSanctions, {
        headers,
        params
      })

      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        console.log(data, '####dataaaaaaa')

        this.sanctions = data.values

        this.lang = language
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionMessages() {
    const token = localStorage.getItem('token')?.toString()
    console.log('11111')

    try {
      runInAction(() => (this.loading = true))
      const params = {
        ...defaultPaging
      }
      const headers = {
        JWT: `${token}`
      }

      const result = await axios.get(urlSanctionMessages, {
        headers,
        params
      })

      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data
        console.log(total, '11111total-sanctions-fetch')

        this.sanctionMessages = data

        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionImplementation() {
    const token = localStorage.getItem('token')?.toString()
    console.log('11111')

    try {
      runInAction(() => (this.loading = true))
      const params = {
        ...defaultPaging
      }
      const headers = {
        JWT: `${token}`
      }

      const result = await axios.get(urlSanctionImplementation, {
        headers,
        params
      })

      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data, total } = result.data

        this.sanctionImplementations = data

        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchSanctionsFilter(language: string, filters: FilterOptions) {
    const token = localStorage.getItem('token')?.toString()

    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.parseFilters(filters),
        ...defaultPaging
      }
      const headers = {
        JWT: `${token}`
      }

      const result = await axios.get(urlSanctions, { params, headers })
      if (result.status !== 200) {
        return console.log('result', result)
      }

      runInAction(() => {
        const { data, total } = result.data

        this.sanctions = data.values
        this.lang = language
        this.filter = this.parseFilters(filters)
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionMessagesFilter(language: string, filters: FilterOptions) {
    const token = localStorage.getItem('token')?.toString()

    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.parseFilters(filters),
        ...defaultPaging
      }
      const headers = {
        JWT: `${token}`
      }

      const result = await axios.get(urlSanctionMessages, { params, headers })
      if (result.status !== 200) {
        return console.log('result', result)
      }

      runInAction(() => {
        const { data, total } = result.data

        this.sanctionMessages = data
        this.lang = language
        this.filter = this.parseFilters(filters)
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionImplementationFilter(
    language: string,
    filters: FilterOptions
  ) {
    const token = localStorage.getItem('token')?.toString()

    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language,
        ...this.parseFilters(filters),
        ...defaultPaging
      }
      const headers = {
        JWT: `${token}`
      }

      const result = await axios.get(urlSanctionImplementation, {
        params,
        headers
      })
      if (result.status !== 200) {
        return console.log('result', result)
      }

      runInAction(() => {
        const { data, total } = result.data

        this.sanctionImplementations = data
        this.lang = language
        this.filter = this.parseFilters(filters)
        this.paging = {
          ...defaultPaging,
          count: Math.ceil(total / defaultPaging.psize)
        }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  private parseFilters = (filters) => {
    const typeIds = filters.type ? returnIds(filters.type) : null
    const dtypeIds = filters.dtype ? returnIds(filters.dtype) : null
    const countryIds = filters.country ? returnIds(filters.country) : null
    const domainIds = filters.domain ? returnIds(filters.domain) : null
    return {
      type: typeIds,
      dtype: dtypeIds,
      country: countryIds,
      domain: domainIds,
      text: filters.text.length ? filters.text : null,
      date_from: filters.period.start
        ? format(filters.period.start, 'yyyy-MM-dd')
        : null,
      date_to: filters.period.end
        ? format(filters.period.end, 'yyyy-MM-dd')
        : null,
      is_economic: filters.is_economic
    }
  }

  async fetchPagingSanctions(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.filter,
        ...this.paging,
        page: newPage
      }
      const token = localStorage.getItem('token')?.toString()

      const headers = {
        JWT: `${token}`
      }
      const result = await axios.get(urlSanctions, { params, headers })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data

        this.sanctions = data.values
        this.paging = { ...this.paging, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchPagingSanctionMessages(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.filter,
        ...this.paging,
        page: newPage
      }
      const token = localStorage.getItem('token')?.toString()

      const headers = {
        JWT: `${token}`
      }
      const result = await axios.get(urlSanctionMessageDetail, {
        params,
        headers
      })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data

        this.sanctionMessages = data
        this.paging = { ...this.paging, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchPagingSanctionImplementation(newPage: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: this.lang,
        ...this.filter,
        ...this.paging,
        page: newPage
      }
      const token = localStorage.getItem('token')?.toString()

      const headers = {
        JWT: `${token}`
      }
      const result = await axios.get(urlSanctionImplementation, {
        params,
        headers
      })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data

        this.sanctionImplementations = data
        this.paging = { ...this.paging, page: newPage }
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  async fetchDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const token = localStorage.getItem('token')?.toString()

      const headers = {
        JWT: `${token}`
      }
      const result = await axios.get(`${urlDetail}/${id}`, { params, headers })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data

        this.detail = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionMessageDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const token = localStorage.getItem('token')?.toString()

      const headers = {
        JWT: `${token}`
      }
      const result = await axios.get(`${urlSanctionMessageDetail}/${id}`, {
        params,
        headers
      })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data

        this.sanctionMessageDetail = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }
  async fetchSanctionImplementationDetail(language: string, id: number) {
    try {
      runInAction(() => (this.loading = true))
      const params = {
        lang: language
      }
      const token = localStorage.getItem('token')?.toString()

      const headers = {
        JWT: `${token}`
      }
      const result = await axios.get(`${urlSanctionImplementation}/${id}`, {
        params,
        headers
      })
      if (result.status !== 200) {
        return console.log('result', result)
      }
      runInAction(() => {
        const { data } = result.data

        this.sanctionImplementationDetail = data
        this.lang = language
      })
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => (this.loading = false))
    }
  }

  public cleanPage = () => {
    this.sanctions = []
    this.sanctionMessages = []
    this.sanctionImplementations = []
    this.detail = defaultSanctionsDetail
    this.sanctionMessageDetail = defaultSanctionMessage
    this.sanctionImplementationDetail = defaultSanctionImplementation
    this.partners = []
    this.paging = defaultPaging
    this.filter = {}
  }

  public cleanDetail = () => {
    this.sanctionMessageDetail = defaultSanctionMessage
    this.sanctionImplementationDetail = defaultSanctionImplementation
  }
}

export const sanctionsService = new SanctionsService()
