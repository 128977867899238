import { Box, Breadcrumbs, useMediaQuery } from '@material-ui/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { getUrlAdress, scrollTopPage } from 'utils'
import { useBreadcrumbStyles } from './Breadcrumb.style'
import { BreadcrumbProps } from './Breadcrumb.types'
import { NavigateNext } from '@material-ui/icons'
import { useMenuState } from 'context/MenuState'
import { UrlBreadcrumd } from 'models'

const Breadcrumb: FC<BreadcrumbProps> = ({ title, castomLink = '' }) => {
  const location = useLocation()
  console.log(location.pathname, 'location.pathname')

  const urls = getUrlAdress(location.pathname)
  const { setOpenMenu } = useMenuState()
  const { t } = useTranslation()
  const titles = title ? title : t(urls[urls.length - 1].name)
  const classes = useBreadcrumbStyles()
  const isMobile = useMediaQuery('(max-width:767px)')

  const arr: UrlBreadcrumd[] = []
  if (Array.isArray(urls) && urls?.length > 0) {
    urls.map((item) => {
      if (
        !item.link.includes('sanctions/messages') &&
        !item.link.includes('sanctions/implementation')
      ) {
        arr.push(item)
      }
    })
  }
  const Finish = castomLink.length ? (
    <Link
      color="inherit"
      to={`${castomLink}`}
      className={classes.link}
      onClick={scrollTopPage}
    >
      {titles}
    </Link>
  ) : (
    <Box className={classes.link}>{titles}</Box>
  )

  return (
    <Breadcrumbs
      id="breadcrumbs"
      className={classes.container}
      separator={<NavigateNext fontSize="small" className={classes.navigate} />}
      onMouseOver={() => setOpenMenu(false)}
    >
      <Link
        color="inherit"
        to="/"
        className={classes.link}
        onClick={scrollTopPage}
      >
        {t('home')}
      </Link>
      {arr.map(({ name, link }, index) => {
        if (isMobile && index > 1) return null
        if (
          location.pathname.indexOf('analytics') !== -1 &&
          location.search.length
        ) {
          return (
            <Link
              key={name}
              color="inherit"
              to={`${link}`}
              className={classes.link}
              onClick={scrollTopPage}
            >
              {t(name)}
            </Link>
          )
        }
        if (index === urls.length - 1) return null
        return (
          <Link
            key={name}
            color="inherit"
            to={`${link}`}
            className={classes.link}
            onClick={scrollTopPage}
          >
            {t(name)}
          </Link>
        )
      })}
      {isMobile ? null : Finish}
    </Breadcrumbs>
  )
}

export default Breadcrumb
