import { FC, Fragment, memo, useEffect, useRef, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField, Checkbox, Popper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useAutocompleteFilterStyles } from './AutocompleteFilter.styles'
import { IAutocompleteFilterProps } from './AutocompleteFilter.types'
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'

const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

const FitContentPopper = function (props) {
  const classes = useAutocompleteFilterStyles()
  return (
    <Popper
      {...props}
      // These styles should not be taken out when refactoring code
      style={{ minWidth: props.style.width, maxWidth: 'fit-content' }}
      placement="bottom-start"
    >
      <span className={classes.popperContent}>{props.children}</span>
    </Popper>
  )
}

const getLengthOfSelected = (options): number => {
  const isAll = options.find((item) => item.Id === -1)
  return isAll ? options.length - 1 : options.length
}

const removeAllElement = (options) => {
  const allIndex = options.findIndex((item) => item.Id === -1)
  options.splice(allIndex, 1)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AutocompleteFilter: FC<IAutocompleteFilterProps<any>> = memo(
  ({
    options,
    checkedValues,
    optionLabelField,
    onChange,
    onOpen,
    onClose,
    loading,
    label,
    disabled,
    optionSelectedFieldKey,
    hasAll = false
  }) => {
    const activeAllElement = useRef(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [optionsWithAll, setOptionsWithAll] = useState<any>([])
    const [optionsLength, setOptionsLength] = useState(0)
    const classes = useAutocompleteFilterStyles()
    const { t } = useTranslation()

    const isLoading =
      loading && optionsWithAll.length === 0 && optionsLength === 0

    useEffect(() => {
      if (options && options.length !== 0) {
        const temp = [...options]
        hasAll && temp.unshift({ Id: -1, [optionLabelField]: t('all') })
        setOptionsWithAll(temp)
        setOptionsLength(temp?.length)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, hasAll, optionLabelField])

    const handleAllChange = (_, value, __, details) => {
      const isAllElement = details?.option?.Id === -1
      if (isAllElement) {
        if (activeAllElement.current) {
          activeAllElement.current = false
          onChange([])
          return
        }
        onChange(optionsWithAll)
        activeAllElement.current = true
        return
      }
      const newOptions = [...value]
      if (activeAllElement.current) {
        if (value.length < optionsLength) {
          activeAllElement.current = false
          removeAllElement(newOptions)
        }
      }
      onChange(newOptions)
    }

    const defaultOption = (option, selected) => {
      return (
        <Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={selected}
            className={classes.icon}
          />
          {option[optionLabelField]}
        </Fragment>
      )
    }

    return (
      <Autocomplete
        multiple
        disabled={disabled}
        classes={{ popper: classes.popperContent, root: classes.closeFilter }}
        loading={isLoading}
        loadingText={t('loading')}
        onOpen={onOpen}
        onChange={handleAllChange}
        onClose={onClose}
        options={optionsWithAll}
        value={checkedValues}
        disablePortal
        disableCloseOnSelect
        renderTags={(selected) => (
          <span>{`${getLengthOfSelected(selected)} ${t('selected')}`}</span>
        )}
        getOptionLabel={(option) => option[optionLabelField]}
        getOptionSelected={(option, value) =>
          option[optionSelectedFieldKey] === value[optionSelectedFieldKey]
        }
        renderOption={(option, { selected }) => defaultOption(option, selected)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={checkedValues?.length ? '' : t(label)}
            size="small"
            className={classes.textFiled}
          />
        )}
        PopperComponent={(props) => (
          <FitContentPopper
            {...props}
            className={`${props.className}${hasAll ? ' has-all' : ''}`}
          />
        )}
      />
    )
  }
)

AutocompleteFilter.displayName = 'AutocompleteFilter'

export default AutocompleteFilter
