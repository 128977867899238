import axios from 'axios'

const url_register = '/api/user/register'
export const Register = (username: string, email: string, password: string) => {
  return axios
    .post(url_register, {
      name: username,
      email,
      pass: password
    })
    .then((response) => {
      console.log(response, 'response')
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const Login = (login: string, pass: string) => {
  // const navigate = useHistory()
  const params = {
    login,
    pass
  }
  return axios
    .post(`/api/token`, {
      ...params
    })
    .then((response) => {
      return response.data
    })
}

export const logout = () => {
  localStorage.removeItem('user')
}

export const getCurrentUser = () => {
  const userStr = localStorage.getItem('user')
  if (userStr) return JSON.parse(userStr)

  return null
}
