// import { FC } from "react";
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
// import { SanctionsCardProps } from "../Cards.types";
// import { EventsCardProps } from "../Cards.types";
import { Link } from 'react-router-dom'
import { useSanctionsCardStyles } from './SanctionsCard.styles'
// import Tag from "components/common/Tag";
// import { format } from 'date-fns'
import { Event } from '@material-ui/icons'
import { scrollTopPage } from 'utils'
import HtmlElement from 'components/common/HtmlElement'
// import { routers } from 'routers'
import { format } from 'date-fns'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { FC } from 'react'
interface SanctionsCardProps {
  item
  type: string
}
const SanctionsCard: FC<SanctionsCardProps> = ({ item }) => {
  console.log(item, 'itemmmmmm')

  const classes = useSanctionsCardStyles()
  const isEconomic = item.field_sanction_is_economic[0]
  const isMobile = useMediaQuery('(max-width:600px)')

  const TagsContent = (
    <Box width={'100%'}>
      <Grid container spacing={2} className={classes.tagContainer}>
        <Grid item className={classes.gridBox}>
          <Box className={classes.dateBox}>
            <Event className={classes.icon} />
            {format(new Date(item.field_sanction_date[0]), 'dd.MM.yyyy')}
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          {' '}
          <Box className={classes.flagIcon}>
            <img src={item.field_sanction_country[0].icon} alt={'flag'} />
          </Box>
          <Box className={classes.countryName}>
            <Typography className={classes.name}>
              {item.field_sanction_country[0].name}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.name}>
            {item.field_sanction_type[0].name}
          </Typography>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.name}>
            {isEconomic ? 'Экономические' : 'Неэкономические'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
  const DomainContent = (
    <Grid item className={classes.domainContainer}>
      {!isMobile && <Typography className={classes.domain}>Сферы:</Typography>}
      {item.field_sanction_domain?.length && (
        <Grid container item className={classes.domains}>
          {item.field_sanction_domain.slice(0, 6).map((dom) => (
            <Grid item md={3} key={dom.id} className={classes.domainText}>
              <AssignmentOutlinedIcon className={classes.icon} />
              <Typography className={classes.domainName}>{dom.name}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  )

  return (
    <Link to={`sanctions/${item.id}`} onClick={scrollTopPage}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.bodyContainer}>
          <Grid item className={classes.about}>
            <Box className={classes.informationContainer}>
              <Box className={classes.tagBox}>{TagsContent}</Box>
            </Box>
          </Grid>
          <Grid item className={classes.gridLabel}>
            <Typography className={classes.labelTitle}>
              Список ограничений
            </Typography>
          </Grid>
          <HtmlElement
            component="div"
            classes={'crop-height'}
            html={item.field_sanction_restrictions[0]}
          />
          <Grid item className={classes.gridLabel}>
            <Typography className={classes.labelTitle}>
              Значение санкций
            </Typography>
          </Grid>
          <HtmlElement
            component="div"
            classes={'crop-height'}
            html={item.field_sanction_value[0].name || ''}
          />
        </Grid>
        {DomainContent}

        <Grid item xs={12} className={classes.bttonGrid}>
          <Link to={`sanctions/${item.id}`}>Подробнее...</Link>
        </Grid>
      </Grid>
    </Link>
  )
}

export default SanctionsCard
