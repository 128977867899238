import { FC, useState } from 'react'
import AutocompleteFilter from 'components/common/AutocompleteFilter'
import { observer } from 'mobx-react-lite'
import { FilterCombinedProps, FilterOptions } from 'models/filters'
// import { filtersService } from 'services/filters/filters.service'

const SanctionImplementationTypeFilter: FC<
  FilterCombinedProps<FilterOptions>
> = ({ onChange, checkedValues }) => {
  // const { sanctionMessageType, loading } = filtersService
  const [data, setData] = useState([{ id: 340, name: 'Физическое лицо' }])

  const handleChange = (value) => {
    console.log(value, '##valuefilter')

    onChange({ ...checkedValues, dtype: value })
  }
  const options = [
    { id: 340, name: 'Физическое лицо' },
    { id: 341, name: 'Юридическое лицо' }
  ]
  const handleOpen = () => {
    setData(options)
    // filtersService.fetchSanctionMessagesTypes()
  }
  // console.log({ sanctionMessageType }, '####sanctionMessagesTypes')

  return options?.length ? (
    <AutocompleteFilter
      options={data}
      // loading={loading}
      label="Объект санкций"
      checkedValues={checkedValues.dtype}
      optionLabelField="name"
      optionSelectedFieldKey="name"
      onOpen={handleOpen}
      onChange={(e) => handleChange(e)}
      hasAll
    />
  ) : null
}

export default observer(SanctionImplementationTypeFilter)
