// import { FC } from "react";
import { Box, Grid, Typography } from '@material-ui/core'
// import { SanctionsCardProps } from "../Cards.types";
// import { EventsCardProps } from "../Cards.types";
import { Link } from 'react-router-dom'
import { useSanctionsCardStyles } from './SanctionsCard.styles'
// import Tag from "components/common/Tag";
// import { format } from 'date-fns'
import { Event } from '@material-ui/icons'
import { scrollTopPage } from 'utils'
import HtmlElement from 'components/common/HtmlElement'
// import { routers } from 'routers'
import { format } from 'date-fns'
import { FC } from 'react'
interface SanctionsCardProps {
  item
  type: string
}
const SanctionMessagesCard: FC<SanctionsCardProps> = ({ item }) => {
  console.log(item, 'itemmmmmm')

  const classes = useSanctionsCardStyles()

  const TagsContent = (
    <Box width={'100%'}>
      <Grid container spacing={2} className={classes.tagContainer}>
        <Grid item className={classes.gridBox}>
          <Box className={classes.dateBox}>
            <Event className={classes.icon} />
            {format(new Date(item.date), 'dd.MM.yyyy')}
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Box className={classes.countryName}>
            <Typography className={classes.name}>{item.country}</Typography>
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.label}>Тип источника: </Typography>
          <Typography className={classes.value}>{item.type}</Typography>
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <Link to={`sanctions/messages/${item.id}`} onClick={scrollTopPage}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.bodyContainer}>
          <Grid item className={classes.about}>
            <Box className={classes.informationContainer}>
              <Box className={classes.tagBox}>{TagsContent}</Box>
            </Box>
          </Grid>
          {/* {type == 'message' && (
            <Box className={classes.labelBox}>
              <Typography className={classes.label}>Значение: </Typography>
              <Typography className={classes.value}>{item.value}</Typography>
            </Box>
          )} */}

          <Grid item className={classes.gridLabel}>
            <Typography className={classes.labelTitle}>
              Значение санкций
            </Typography>
          </Grid>
          <HtmlElement
            component="div"
            classes={'crop-height'}
            html={item.value}
          />
          <Grid item className={classes.gridLabel}>
            <Typography className={classes.labelTitle}>
              Содержание сообщения (кто инициирует, о чем речь)
            </Typography>
          </Grid>
          <HtmlElement
            component="div"
            classes={'crop-height'}
            html={item.body}
          />
        </Grid>

        <Grid item xs={12} className={classes.bttonGrid}>
          <Link to={`sanctions/messages/${item.id}`}>Подробнее...</Link>
        </Grid>
      </Grid>
    </Link>
  )
}

export default SanctionMessagesCard
