import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import Breadcrumb from 'components/common/Breadcrumb'
import SectionTitle from 'components/common/SectionTitle'
import { useLanguage } from 'context/Translation'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { FC, Fragment, useEffect } from 'react'
import { getUrlAdress } from 'utils'
import { useSanctionStyles } from '../Sanction/Sanctions.styles'
import queryString from 'query-string'

import HtmlElement from 'components/common/HtmlElement'
import Spiner from 'components/common/Spiner'
// import { useTranslation } from 'react-i18next'
import { sanctionsService } from 'services/sanctions/sanctions.service'
import { useLocation } from 'react-router-dom'
const SanctionMessageDetails: FC = () => {
  const classes = useSanctionStyles()
  const url = getUrlAdress(window.location.pathname)
  const id = url[url.length - 1].name
  const { language } = useLanguage()
  const location = useLocation()

  // Extract the active tab from the query string
  const { tab = 0 } = queryString.parse(location.search)
  console.log({ tab }, '###tabbbbbbbbbbb')
  // const { t } = useTranslation()
  const { sanctionMessageDetail, lang, loading } = sanctionsService

  useEffect(() => {
    if (id === null) return
    if (sanctionMessageDetail.id !== Number(id)) {
      sanctionsService.fetchSanctionMessageDetail(language, Number(id))
      return
    }
    if (lang !== language) {
      sanctionsService.fetchSanctionMessageDetail(language, Number(id))
      return
    }
  }, [sanctionMessageDetail, language, id])

  useEffect(() => {
    return function cleanupPage() {
      sanctionsService.cleanDetail()
    }
  }, [])

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isEnd />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    )
  }

  const TagsContent = (
    <Box width={'100%'}>
      <Grid container spacing={2} className={classes.tagContainer}>
        <Grid item className={classes.gridBox}>
          <Box className={classes.dateBox}>
            <Event className={classes.icon} />
            {sanctionMessageDetail.date
              ? format(new Date(sanctionMessageDetail.date), 'dd.MM.yyyy')
              : null}
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Box className={classes.countryName}>
            <Typography className={classes.name}> Страна:</Typography>

            <Typography className={classes.name}>
              {sanctionMessageDetail.country}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.name}> Тип источника:</Typography>
          {sanctionMessageDetail?.type}
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <Fragment>
      <SectionTitle isEnd isShown={false} />
      <Breadcrumb title="подробности" />
      <Box className={classes.container}>
        <Grid container direction="column">
          <Grid item xs container>
            <Grid item xs>
              <Box className={classes.headerBackground}>
                <Box className={`${classes.text} `}>
                  <Box className={classes.tagBox}>{TagsContent}</Box>
                </Box>
              </Box>
              {sanctionMessageDetail.value && (
                <Box className={`${classes.valueBox} ${classes.textMargin} `}>
                  <Typography className={classes.label}>Значение </Typography>
                  <Typography className={classes.value}>
                    {sanctionMessageDetail.value}
                  </Typography>
                </Box>
              )}
              {sanctionMessageDetail.body && (
                <Box className={`${classes.valueBox} ${classes.textMargin} `}>
                  <Typography className={classes.label}>
                    Содержание сообщения (кто инициирует, о чем речь){' '}
                  </Typography>

                  <HtmlElement
                    component="div"
                    classes={`${classes.text} `}
                    html={sanctionMessageDetail.body}
                  />
                </Box>
              )}

              {sanctionMessageDetail.comment && (
                <Box className={`${classes.comment} ${classes.textMargin} `}>
                  <Typography className={classes.name}>
                    {' '}
                    Экспертный комментарий
                  </Typography>

                  <HtmlElement
                    component="div"
                    classes={`${classes.text} `}
                    html={sanctionMessageDetail.comment}
                  />
                </Box>
              )}

              {sanctionMessageDetail.source?.length && (
                <Box className={`${classes.text} ${classes.textMargin}`}>
                  <a
                    href={sanctionMessageDetail.source[0].value}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button className={classes.linkButton}>
                      Ссылка на документ
                    </Button>
                  </a>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default observer(SanctionMessageDetails)
