import { Box, Grid, MenuItem, Select, Tab, Tabs } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Breadcrumb from 'components/common/Breadcrumb'
import SanctionImplementationCard from 'components/common/Cards/SanctionsCard/SanctionImplementationCard'
import SanctionMessagesCard from 'components/common/Cards/SanctionsCard/SanctionMessagesCard'
import SanctionsCard from 'components/common/Cards/SanctionsCard/SanctionsCard'
import SanctionFilter from 'components/common/Filters/SanctionFilter'
import Paging from 'components/common/Paging'
import SectionTitle from 'components/common/SectionTitle'
import Spiner from 'components/common/Spiner'
import EmptyFilterResult from 'components/common/StubsAndBugs/EmptyFilterResult'
import { useLanguage } from 'context/Translation'
import { observer } from 'mobx-react-lite'
import { FilterCombinedProps, FilterOptions } from 'models/filters'
import { FC, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
import { sanctionsService } from 'services/sanctions/sanctions.service'
import { scrollTopPage } from 'utils'
import { useSanctionStyles } from './Sanctions.styles'
import queryString from 'query-string'
const ERROR_MESSAGES = {
  wrongCredentials: 'Wrong credentials, please try again.',
  noPermissions: "You don't have permissions to view sanctions.",
  serverError: 'Something went wrong. Please try again later.',
  sessionExpired: 'Session expired, Please login again'
}

const SanctionPage: FC<FilterCombinedProps<FilterOptions>> = observer(
  ({ checkedValues, onChange }) => {
    const location = useLocation()
    const history = useHistory()
    const { tab: queryTab } = queryString.parse(location.search)
    const [activeTab, setActiveTab] = useState(Number(queryTab) || 0)

    // Extract `tab` from the query string in the URL
    // const [activeTab, setActiveTab] = useState(Number(queryTab) || 0)
    const {
      sanctions,
      paging,
      loading,
      sanctionMessages,
      sanctionImplementations
    } = sanctionsService
    const classes = useSanctionStyles()
    const { language } = useLanguage()
    const isMobile = useMediaQuery('(max-width:600px)')

    const checkValidToken = () => {
      const expirationDate = localStorage.getItem('expired')
      const expirationTime = Number(expirationDate)
      const currentTime = Math.floor(Date.now() / 1000)

      if (currentTime >= expirationTime) {
        localStorage.clear()
        toast.error(ERROR_MESSAGES.sessionExpired)
        history.push('/login')
      }
    }
    useEffect(() => {
      const currentQuery = queryString.stringify({ tab: activeTab })
      history.replace({ search: currentQuery })
    }, [activeTab, history])

    const handleTabChange = (_: any, newTab: number) => {
      checkValidToken()
      setActiveTab(newTab)
    }

    useEffect(() => {
      const { tab } = queryString.parse(location.search)
      setActiveTab(Number(tab) || 0)
    }, [location.search])
    useEffect(() => {
      const authUser = localStorage.getItem('authorizeStatus')
      if (!authUser || ['2', '3'].includes(authUser)) {
        toast.error(ERROR_MESSAGES.noPermissions)
        history.push('/login')
      }
      checkValidToken()
    }, [history])

    const fetchData = async (tab: number) => {
      if (tab === 0) {
        await sanctionsService.fetchSanctions(language)
      } else if (tab === 1) {
        await sanctionsService.fetchSanctionMessages()
      } else if (tab === 2) {
        await sanctionsService.fetchSanctionImplementation()
      }
    }

    useEffect(() => {
      fetchData(activeTab)
    }, [activeTab, language])

    const handlePageChange = (page: number) => {
      if (activeTab === 0) {
        sanctionsService.fetchPagingSanctions(page)
      } else if (activeTab === 1) {
        sanctionsService.fetchPagingSanctionMessages(page)
      } else {
        sanctionsService.fetchPagingSanctionImplementation(page)
      }
      scrollTopPage()
    }

    // const handleTabChange = (_: any, newTab: number) => {
    //   checkValidToken()
    //   setActiveTab(newTab)
    // }

    const handleFindClick = () => {
      if (activeTab === 0) {
        sanctionsService.fetchSanctionsFilter(language, checkedValues)
      } else if (activeTab === 1) {
        sanctionsService.fetchSanctionMessagesFilter(language, checkedValues)
      } else {
        sanctionsService.fetchSanctionImplementationFilter(
          language,
          checkedValues
        )
      }
    }

    return (
      <>
        <SectionTitle background="sanction">
          <SanctionFilter
            checkedValues={checkedValues}
            onChange={onChange}
            onFind={handleFindClick}
            type={
              activeTab === 0
                ? 'sanction'
                : activeTab === 1
                ? 'message'
                : 'implementation'
            }
          />
        </SectionTitle>
        <Breadcrumb />

        {isMobile ? (
          <Grid container>
            <Grid item xs={12} className={classes.gridSelect}>
              <Select
                value={activeTab}
                onChange={(e) =>
                  handleTabChange(null, e.target.value as number)
                }
                fullWidth
                disableUnderline
                className={classes.select}
              >
                <MenuItem className={classes.menu} value={0}>
                  База данных антироссийских санкций SanctionsRuBase+
                </MenuItem>
                <MenuItem className={classes.menu} value={1}>
                  Мониторинг конфискации российских активов и доходов от
                  российских активов
                </MenuItem>
                <MenuItem className={classes.menu} value={2}>
                  Санкционные ограничения в отношении физических и юридических
                  лиц
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
        ) : (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label='База данных антироссийских санкций "SanctionsRuBase+"'
            />
            <Tab
              className={classes.tab}
              label="Мониторинг конфискации российских активов и доходов от российских активов"
            />
            <Tab
              className={classes.tab}
              label="Санкционные ограничения в отношении физических и юридических лиц"
            />
          </Tabs>
        )}

        {loading ? (
          <Spiner />
        ) : (
          <Box className={classes.container}>
            {activeTab === 0 && (
              <Grid container spacing={2} className={classes.contentContainer}>
                {sanctions.length ? (
                  sanctions.map((item, idx) => (
                    <Grid item xs={12} key={idx}>
                      <SanctionsCard item={item} type="sanction" />
                    </Grid>
                  ))
                ) : (
                  <EmptyFilterResult />
                )}
              </Grid>
            )}
            {activeTab === 1 && (
              <Grid container spacing={2} className={classes.contentContainer}>
                {sanctionMessages.length ? (
                  sanctionMessages.map((item, idx) => (
                    <Grid item xs={12} key={idx}>
                      <SanctionMessagesCard item={item} type="message" />
                    </Grid>
                  ))
                ) : (
                  <EmptyFilterResult />
                )}
              </Grid>
            )}
            {activeTab === 2 && (
              <Grid container spacing={2} className={classes.contentContainer}>
                {sanctionImplementations.length ? (
                  sanctionImplementations.map((item, idx) => (
                    <Grid item xs={12} key={idx}>
                      <SanctionImplementationCard
                        item={item}
                        type="implementation"
                      />
                    </Grid>
                  ))
                ) : (
                  <EmptyFilterResult />
                )}
              </Grid>
            )}
            <Paging paging={paging} onChange={handlePageChange} />
          </Box>
        )}
      </>
    )
  }
)

export default SanctionPage
