import { FC, Fragment, useEffect } from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Breadcrumb from 'components/common/Breadcrumb'
import { useSanctionStyles } from './Sanctions.styles'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import { getUrlAdress } from 'utils'
import { useLanguage } from 'context/Translation'
import { observer } from 'mobx-react-lite'
import { format } from 'date-fns'
import Spiner from 'components/common/Spiner'
import { sanctionsService } from 'services/sanctions/sanctions.service'
import HtmlElement from 'components/common/HtmlElement'
import Line from 'components/common/Line'
import Tag from 'components/common/Tag'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
const SanctionsDetail: FC = () => {
  const classes = useSanctionStyles()
  const url = getUrlAdress(window.location.pathname)
  const id = url[url.length - 1].name
  const { language } = useLanguage()

  const { t } = useTranslation()
  const { detail, lang, loading } = sanctionsService
  const location = useLocation()
  const { tab = 0 } = queryString.parse(location.search)
  console.log({ tab }, '###tabbbbbbbbbbb')

  useEffect(() => {
    if (id === null) return
    if (detail.id.value !== Number(id)) {
      sanctionsService.fetchDetail(language, Number(id))
      return
    }
    if (lang !== language) {
      sanctionsService.fetchDetail(language, Number(id))
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, language, id])

  useEffect(() => {
    return function cleanupPage() {
      sanctionsService.cleanDetail()
    }
  }, [])

  if (loading) {
    return (
      <Fragment>
        <SectionTitle isEnd />
        <Box className={classes.container}>
          <Spiner />
        </Box>
      </Fragment>
    )
  }
  const isEconomic = detail.field_sanction_is_economic.value

  const TagsContent = (
    <Box width={'100%'}>
      <Grid container spacing={2} className={classes.tagContainer}>
        <Grid item className={classes.gridBox}>
          <Box className={classes.dateBox}>
            <Event className={classes.icon} />
            {detail.field_sanction_date
              ? format(new Date(detail.field_sanction_date.value), 'dd.MM.yyyy')
              : null}
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Box className={classes.flagIcon}>
            <img
              src={detail.field_sanction_country.value[0].icon}
              alt={'flag'}
            />
          </Box>
          <Box className={classes.countryName}>
            <Typography className={classes.name}>
              {detail.field_sanction_country.value[0].name}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.name}>Сфера:</Typography>
          {detail?.field_sanction_domain?.value?.map((element) => (
            <>
              <Typography>{element.name}</Typography>
            </>
          ))}
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.name}>Значение:</Typography>
          {detail?.field_sanction_value?.value?.map((element) => (
            <>
              <Typography>{element.name}</Typography>
            </>
          ))}
        </Grid>
        <Grid item className={classes.gridBox}>
          <Typography className={classes.name}> Тип санкций:</Typography>
          {detail?.field_sanction_type?.value
            .map((item) => item.name)
            .join(' , ')}{' '}
          {isEconomic ? t('economic') : t('not-economic')}
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <Fragment>
      <SectionTitle isEnd isShown={false} />
      <Breadcrumb title="подробности" />
      <Box className={classes.container}>
        <Grid container direction="column">
          <Grid item xs container>
            <Grid item xs>
              <Box className={classes.headerBackground}>
                <Box className={`${classes.text} `}>
                  <Box className={classes.tagBox}>{TagsContent}</Box>
                </Box>
              </Box>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}>
                  Список ограничений
                </Typography>
                <HtmlElement
                  component="div"
                  classes={`crop-height ${classes.cropHeight}`}
                  html={detail.field_sanction_restrictions.value}
                />
              </Box>

              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}>
                  {' '}
                  Нормативно-правовое обоснование (рус.){' '}
                </Typography>

                <HtmlElement
                  component="div"
                  classes={`crop-height ${classes.cropHeight}`}
                  html={detail.field_sanction_justification.value}
                />
              </Box>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}>
                  {' '}
                  Нормативно-правовое обоснование (англ.){' '}
                </Typography>

                <HtmlElement
                  component="div"
                  html={detail.field_sanction_justification_en.value}
                />
              </Box>
              {detail.field_sanction_comments.value.length ? (
                <Box className={`${classes.text} ${classes.textMargin}`}>
                  <Typography className={classes.name}>
                    {detail.field_sanction_comments.info.label}
                  </Typography>

                  <HtmlElement
                    component="div"
                    html={detail.field_sanction_comments.value[0].value}
                  />
                </Box>
              ) : null}
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}> Санкционер </Typography>

                <HtmlElement
                  component="div"
                  html={detail.field_sanctioner.value[0].name}
                />
              </Box>

              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}>
                  {' '}
                  Список исключений{' '}
                </Typography>

                <HtmlElement
                  component="div"
                  html={detail.field_sanction_exceptions.value[0]}
                />
              </Box>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}>
                  {' '}
                  Обязательность{' '}
                </Typography>

                <HtmlElement
                  component="div"
                  html={detail.field_sanction_required.value[0]}
                />
              </Box>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <Typography className={classes.name}>
                  {' '}
                  Регулярность пересмотра{' '}
                </Typography>

                <HtmlElement
                  component="div"
                  html={detail.field_sanction_revision.value[0]}
                />
              </Box>
              <Box className={`${classes.text} ${classes.textMargin}`}>
                <a
                  href={detail.field_sanction_doc_link.value[0].uri}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button className={classes.linkButton}>
                    Ссылка на документ
                  </Button>
                </a>
              </Box>

              {detail.field_tags ? (
                <Grid item xs style={{ margin: 30 }}>
                  <Line />
                  <Box pt={'30px'} style={{ display: 'flex' }}>
                    {detail.field_tags.value.map((tag) => (
                      <Tag
                        key={tag.id}
                        id={tag.id}
                        title={tag.name}
                        marginRight
                      />
                    ))}
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default observer(SanctionsDetail)
